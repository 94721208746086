<template>
  <v-autocomplete
    v-model="cliente"
    :items="clientes"
    :loading="loadingCliente"
    @click:clear="clearCliente"
    dense
    clearable
    solo
    flat
    hide-details
    hide-no-data
    outlined
    class=""
    item-text="nome_fantasia"
    item-value="id"
    label="Clientes"
    prepend-inner-icon="mdi-account-group"
  >
  </v-autocomplete>
</template>

<script>
import { fetchClientes } from "@/api/gestor/clientes.js";

export default {
  name: "Clientes",

  data() {
    return {
      loadingCliente: true,
      cliente: null,
      clientes: [],
    };
  },

  watch: {
    cliente() {
      if (this.cliente) {
        this.$store.commit("Filtros/UPDATE_CLIENTE", this.cliente);
      }
    },
  },

  methods: {
    clearCliente() {
      this.$store.commit("Filtros/UPDATE_CLIENTE", null);
      this.cliente = null;
    },

    getClientes() {
      this.loadingCliente = true;
      return fetchClientes()
        .then((response) => {
          this.clientes = response;
          this.loadingCliente = false;
        })
        .catch(() => {
          this.loadingCliente = false;
        });
    },
  },

  async mounted() {
    await this.getClientes();
    console.log(this.$route.query);
    if (this.$route.query.cliente_id) {
      let cliente = this.clientes.find(
        (item) => item.id == this.$route.query.cliente_id
      );
      this.cliente = cliente.id;
    }
  },
};
</script>

<style></style>
